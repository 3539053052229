import React, { useState, Component } from 'react'
import { Link } from 'react-router-dom'
import { Nav, Navbar, NavDropdown, Form, FormControl, Button, NavItem, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AuthUserContext } from '../components/Session'
import { withFirebase } from '../components/Firebase'

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  showModel : false,
  pageModel : "sign-in",
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';
const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class AuthenticationModel extends Component {
  constructor(props) {
    super(props);
    
    this.state = { ...INITIAL_STATE };
  }

  onSubmitSignIn = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        // this.props.history.push('/');
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };
  onSubmitSignUp = event => {
    const { username, email, password,firstName,lastName } = this.state;
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set({
          // username,
          email,
          firstName,
          lastName,
        });
      })
      .then(() => {
        // return this.props.firebase.doSendEmailVerification();
        // this.props.firebase.auth.currentUser.reload() // reloads user fields, like emailVerified:
        // if (!this.props.firebase.auth.currentUser.emailVerified) {
        //   this.props.firebase.auth.onAuthStateChanged(function(user) {
        //     user.sendEmailVerification(); 
        //   });
        // }
        // return
        return this.props.firebase.auth.onAuthStateChanged(function(user) { user.sendEmailVerification(); });
      })
      .then(() => {
        // this.setState({ ...INITIAL_STATE });
        this.setState( {...this.state, showModel:false})
        // this.props.history.push('/');
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  render() {

    const showModel = () =>{ this.setState( {...this.state, showModel:true}) }
    const hideModel = () =>{ this.setState( {...this.state, showModel:false}) }
    const signInPageModel = () =>{ this.setState( {...this.state, pageModel:'sign-in'}) }
    const signUpPageModel = () =>{ this.setState( {...this.state, pageModel:'sign-up'}) }
    const forgetPageModel = () =>{ this.setState( {...this.state, pageModel:'forget'}) }

    const { username="", firstName, lastName, email, password, error } = this.state;
    const isInvalid = password === '' || email === '';
    
    return (
      <>
        {/* <Form inline>
          <FormControl type="text" placeholder="User" className="mr-sm-1" />
          <FormControl type="password" placeholder="Pass" className="mr-sm-1" />
          <Button variant="outline-success">Sign In</Button>
        </Form> */}
        <AuthUserContext.Consumer>
          {authUser =>
            authUser ? (
              <NavDropdown title={authUser.email}>
                <NavDropdown.Item href="../" onClick={this.props.firebase.doSignOut}>Log Out</NavDropdown.Item>
              </NavDropdown>
            ) : 
              <Button variant="outline-primary" onClick={showModel}>Sign In</Button>
          }
        </AuthUserContext.Consumer>
        <Modal show={this.state.showModel} onHide={hideModel}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Sign in</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            {/* {JSON.stringify(this.state)} */}
            { 
              this.state.pageModel === "sign-in" ?
              <form onSubmit={this.onSubmitSignIn}>
                <h3 className="text-center">Sign In</h3>

                { error && <div class="alert alert-danger">{error.message} </div>}

                <div className="form-group">
                    <label>Email address</label>
                    <input type="email" name="email" className="form-control" value={email} onChange={this.onChange} placeholder="Enter email" />
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input type="password" name="password" className="form-control" value={password} onChange={this.onChange} placeholder="Enter password" />
                </div>

                {/* <div className="form-group">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                        <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                    </div>
                </div> */}

                <button type="submit" className="btn btn-primary btn-block" disabled={isInvalid}>Sign In</button>
               
                {/* <p className="forgot-password text-right">
                    Forgot <a href="#/" onClick={forgetPageModel}>password?</a>
                </p> */}
                {/* <p className=" text-right">
                Dont have an account yet? <a href="#/" onClick={signUpPageModel}> Sign Up </a>
                </p> */}
                 

               
              </form>
            :""} 

            { 
              this.state.pageModel === "sign-up" ?
                <form onSubmit={this.onSubmitSignUp}>
                  <h3 className="text-center">Sign Up</h3>

                  { error && <div class="alert alert-danger">{error.message} </div>}

                  <div className="form-group">
                      <label>First name</label>
                      <input type="text" name="firstName" className="form-control" value={firstName} onChange={this.onChange} placeholder="Enter first name" />
                  </div>

                  <div className="form-group">
                      <label>Last name</label>
                      <input type="text" name="lastName" className="form-control" value={lastName} onChange={this.onChange} placeholder="Enter last name" />
                  </div>

                  <input type="hidden" name="username" className="form-control" value={username} onChange={this.onChange} placeholder="Enter username" />

                  <div className="form-group">
                      <label>Email address</label>
                      <input type="email" name="email" className="form-control" value={email} onChange={this.onChange} placeholder="Enter email" />
                  </div>

                  <div className="form-group">
                      <label>Password</label>
                      <input type="password" name="password" className="form-control" value={password} onChange={this.onChange} placeholder="Enter password" />
                  </div>

                  <button type="submit" className="btn btn-primary btn-block" disabled={isInvalid}>Sign Up</button>
                  <p className="forgot-password text-right">
                      Already registered <a href="#/" onClick={signInPageModel}>Sign In</a>
                  </p>
                </form>
              :""
                
            }

          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={hideModel}>
              Close
            </Button>
            <Button variant="primary" onClick={hideModel}>
              Save Changes
            </Button>
          </Modal.Footer> */}
        </Modal>
      </>
    );
  }
}

export default withFirebase(AuthenticationModel)

  



