
import React,{useState} from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
// import firebase from '../config/firebase_config';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

/** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
firestore
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const getDoc = (setDoc, setDocId, docName, docId) => {}
export const getDocById = (firebase, setDoc, setDocId, docName, docId) => {
  if(docId){
    firebase.firestore.collection(docName)
    .doc(docId).get().then((doc) => {
        setDocId(doc.id);       console.log(docName+" docId =", doc.id );
        setDoc(doc.data());     console.log(docName+" doc =", JSON.stringify(doc.data()));
    })
  }
}
export const getCol = (firebase, setTable, tableName) => {
  getColOrderBy(firebase,setTable,tableName,null,null)
}
export const getColOrderBy = (firebase, setTable, tableName, orderByColumn, orderByMode) => {
  const db = firebase.firestore;
  if(tableName != null && tableName.length > 0){
    let collection = db.collection(tableName);
    if(orderByColumn != null)
      collection = collection.orderBy(orderByColumn, orderByMode) 
      // collection = collection.orderBy("createDate", "desc") 
    collection    
      .onSnapshot(function(data){
        console.log("fetch "+tableName+" size = ", data.docs.length);
        setTable(data.docs.map(doc => ({...doc.data(), id:doc.id})));
    });
  }
}
export const getColById = (firebase, setDoc, setDocId, docName, docId) => {
  // const db = firebase.firestore;
  // firestore.collection(docName)
  //     .doc(key)
  //     .get().then((doc) => {
  //       setInputList(...inputList, doc.data().inputList);
  //     })
}


// master data
export const loadMaster = (firebase ,master, setMaster, tableName) => {
  const firestore = firebase.firestore;
  firestore.collection(tableName)
  // .orderBy("createDate","desc")
  .onSnapshot(function(data){
    // console.log("mater data = ", data.docs);
    const list= (data.docs.map(doc => ({...doc.data(), value:doc.id} )));
    const masterTemp = master;
    masterTemp[tableName] = list;
    setMaster(masterTemp);
  });
  return ;
}


// Update

export const updateDocById = (firebase, docName, clearDoc, doc, docId) => {
  const db = firebase.firestore;
  console.info("trying update document with id = ", docId);
  console.info(docName+" =  ", JSON.stringify(doc));
  db.collection(docName)
  .doc(docId).set({...doc,updateDate: firebase.serverTimestamp()});
  clearDoc();
  alert('save แล้วนะ');
}

/** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
storage
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export const uploadImage = (firebase, taskCreate, setTaskCreate, path, image) => {
  const storage = firebase.storage;
  if(image){
      const uploadTask = storage.ref(`${path}/${image.name}`).put(image);
      uploadTask.on(
          "state_changed",
          snapshot => {
          // progress function ...
          const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          },
          error => {
          // Error function ...
          console.log(error);
          },
          () => {
          // complete function ...
          storage
              .ref(path)
              .child(image.name)
              .getDownloadURL()
              .then(url => {
                  console.log("uploaded url = ",url);
                  setTaskCreate({ ...taskCreate, image: url });
              });
          }
      );
  }
};
export const uploadImageDynamic = (firebase, taskCreate, setTaskCreate, inputName, image, path) => {
  const storage = firebase.storage;
    if(image){
        const uploadTask = storage.ref(`${path}/${image.name}`).put(image);
        uploadTask.on(
            "state_changed",
            snapshot => {
            // progress function ...
            const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            },
            error => {
            // Error function ...
            console.log(error);
            },
            () => {
            // complete function ...
            storage
                .ref(path)
                .child(image.name)
                .getDownloadURL()
                .then(url => {
                    console.log("uploaded url = ",url);
                    setTaskCreate({ ...taskCreate, [inputName]: url });
                });
            }
        );
    }
};


/** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * 
ETC
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


export const noImage = "../appDefault/600x600.webp";

export const getIdFromPath = (point) => {
  var words = window.location.href.split('/');
  if(words.length == 5){
    var words1 = words[point].split("#")[0];
    var param = words1.split("/")[0];
    console.log("getIdFromPath  = ",param);
    return param;
  }else
    return null;
  
}

export const JsonPretty = (p) =>{
  return <JSONPretty style={{textAlign:"left"}} data={p.data} ></JSONPretty>;
}
// var local = "th-TH";
// export default function Project() {
// }
export const dateFormat = (date) => {
    // console.log(date);
    if(date != null){
        // return new Date(date.toDate()).toLocaleDateString("en-US");
        return new Intl.DateTimeFormat("th-TH", {
        year: "numeric", month: "long", day: "2-digit",
        }).format(date.toDate());
    }
}
export const dateValue = (date) => {
  if(date != null && !(date instanceof Date)){
      console.log("date ===== ", JSON.stringify(date) );
      return new Intl.DateTimeFormat("en-US", {
      year: "numeric", month: "2-digit", day: "2-digit",
      }).format(date.toDate());
  }else
    return date;
}
function toDateTime(secs) {
  var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
}
export const formatDate = (date) => {
  var date1 = date.seconds != null
   ? new Date(date) // Already a javascript date object
   : date;
   return date1;
}
