
import React,{useState, Component, useEffect} from 'react';
import { AuthUserContext } from '../components/Session'
import { withFirebase } from '../components/Firebase';


import { loadMaster, dateFormat, uploadImage, getCol,getColById, getDoc, getDocById ,uploadImageDynamic, getColOrderBy } from '../config/dbUtils'

import { Nav, Navbar, NavDropdown, Form, FormControl, Button, NavItem, Modal, Card, CardDeck,ButtonGroup, Row as Row, Col } from 'react-bootstrap'

import { faGlobe,faFlag, faPhoneSquare} from "@fortawesome/free-solid-svg-icons";
import { faLine, faFacebookSquare, faInstagramSquare, faTwitterSquare, faYoutube,  } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {Pie, Bar, Line, HorizontalBar} from 'react-chartjs-2';
// #####################        Source (pie)        #####################
const data = {
	labels: [
		'Youtube',
		'Facebook',
		'Other'
	],
	datasets: [{
    label: 'จำนวนโพสที่ไม่เหมาะสม',
		data: [24756, 12320, 3093],
		backgroundColor: [
		'#F00',
		'#3B5998',
		'#1DA1F2'
		],
		hoverBackgroundColor: [
		'#F00',
		'#3B5998',
		'#1DA1F2'
		]
	}]
};
const option = {
  legend: { 
    position: 'bottom' 
  },
  tooltips: {
    // responsive: true,
    //         legend: {
    //             position: 'bottom',
    //             display: true,
 
    //         },
    callbacks: {
      label: function(tooltipItem, data) {
        var dataset = data.datasets[tooltipItem.datasetIndex];
        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
        var total = meta.total;
        var currentValue = dataset.data[tooltipItem.index];
        var percentage = parseFloat((currentValue/total*100).toFixed(1));
        return currentValue + ' (' + percentage + '%)';
      },
      title: function(tooltipItem, data) {
        return data.labels[tooltipItem[0].index];
      }
    }
  }
}
// #####################        Source per year (line)        #####################
const data_source_per_year = {
	labels: ['ปี 2559', 'ปี 2560', 'ปี 2561', 'ปี 2562', 'ปี 2563'],
	datasets: [{
    label: 'จำนวนโพสที่ไม่เหมาะสม',
    data: [24756, 12320, 3093],
		backgroundColor: [
		'#3B5998',
		'#3B5998',
		'#3B5998'
		],
		hoverBackgroundColor: [
		'#F00',
		'#F00',
		'#F00'
    ],
    data: [5351, 11042, 13908, 5777, 4082],
      backgroundColor: "#4082c4"
	}]
};
const option_source_per_year = {
  responsive: true,
            legend: {
                position: 'bottom',
                display: true,
 
            },
            "hover": {
              "animationDuration": 0
            },
             "animation": {
                "duration": 1,
              "onComplete": function() {
                var chartInstance = this.chart,
                  ctx = chartInstance.ctx;
 
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
 
                this.data.datasets.forEach(function(dataset, i) {
                  var meta = chartInstance.controller.getDatasetMeta(i);
                  meta.data.forEach(function(bar, index) {
                    var data = dataset.data[index];
                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                  });
                });
              }
            },
            title: {
                display: false,
                text: ''
            },
  scales: { 
      yAxes: [{
          ticks: {
            max: Math.max(...data.datasets[0].data) + 10,
            beginAtZero: true
          }
      }]
  }
}

// #####################        Source per year (bar)        #####################

const dataLine = {
  labels: ['ปี 2559', 'ปี 2560', 'ปี 2561', 'ปี 2562', 'ปี 2563'],
  datasets: [
    {
      label: 'จำนวนโพสที่ไม่เหมาะสม',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [5351, 11042, 13908, 5777, 4082],
      backgroundColor: "#4082c4"
    }
  ]
};
const optionLine = {
  "hover": {
    "animationDuration": 0
  },
  "animation": {
    "duration": 1,
    "onComplete": function() {
      var chartInstance = this.chart,
        ctx = chartInstance.ctx;

      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.fontStyle = "bold";
      ctx.fillStyle = "#FF0000";

      this.data.datasets.forEach(function(dataset, i) {
        var meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach(function(bar, index) {
          var data = dataset.data[index];
          ctx.fillText(data, bar._model.x, bar._model.y - 5);
        });
      });
    }
  },
  scales: { 
      yAxes: [{
          ticks: {
            max: Math.max(...data.datasets[0].data) + 10,
            beginAtZero: true
          }
      }]
  }
}

// #####################        Poster by Source (Ho Bar)        #####################

const data_poster_by_source = {
  labels: ['Mr. A', 'Mr. B', 'Mr. C', 'Mr. D', 'Mr. E', 'Mr. F', 'Mr. G', 'Mr. H', 'Mr. I', 'Mr. J'
          ,'Mr. K', 'Mr. L', 'Mr. M', 'Mr. N', 'Mr. O', 'Mr. P', 'Mr. Q', 'Mr. R', 'Mr. S', 'Mr. T'],
  datasets: [
    {
      label: 'จำนวนโพส',
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [95, 89, 85, 79, 76, 71, 68, 65, 62, 60
            ,58, 54, 52, 48, 30, 29, 22, 12, 7, 5]
    }
  ]
};
const option_poster_by_source = {
  "hover": {
    "animationDuration": 0
  },
  "animation": {
    "duration": 1,
    "onComplete": function() {
      var chartInstance = this.chart,
        ctx = chartInstance.ctx;

      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.fontStyle = "bold";
      ctx.fillStyle = "#0000FF";

      this.data.datasets.forEach(function(dataset, i) {
        var meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach(function(bar, index) {
          var data = dataset.data[index];
          ctx.fillText(data, bar._model.x + 10, bar._model.y + 5);
        });
      });
    }
  },
  scales: { 
      yAxes: [{
          ticks: {
            max: Math.max(...data_poster_by_source.datasets[0].data) + 10,
            beginAtZero: true
          }
      }],
      xAxes: [{
        ticks: {
          max: Math.max(...data_poster_by_source.datasets[0].data) + 10,
          beginAtZero: true
        }
    }]
  }
}





const PageFunction = (props) => {
  const firebase = props.firebase;
  const firestore = props.firebase.firestore;
  const db = props.firebase.firestore;
  const collection = db.collection("defame");
  
  const [websiteList, setWebsiteList] = useState([]);
  const [websiteCreate, setWebsiteCreate] = useState({startDate:new Date()})
  const [websiteUpdate, setWebsiteUpdate] = useState({});
  const [chart1, setChart1] = useState({});
  // const [master, setMaster] = useState({});
  // const masterList = ["m1","m2"];

  React.useEffect(()=>{
    fetchData(0);
    getChart1()
    

    // // Initialize the sharded counter.
    // var visits = new sharded.Counter(db.doc("pages/hello-world"), "visits");

    // // Increment the field "visits" of the document "pages/hello-world".
    // visits.incrementBy(1);

    // // Listen to locally consistent values.
    // visits.onSnapshot((snap) => {
    //   console.log("Locally consistent view of visits: " + snap.data());
    // });

    // // Alternatively, if you don't mind counter delays, you can listen to the document directly.
    // db.doc("pages/hello-world").onSnapshot((snap) => {
    //   console.log("Eventually consistent view of visits: " + snap.get("visits"));
    // });
  
    // console.log("aaaaa = ",a)
  },[]);
  // https://fireship.io/snippets/firestore-increment-tips/
  const increment = async () => {
    const storyRef = db.collection('stories').doc('hello-world');
    storyRef.update({ reads: db.FieldValue.increment(1) });
  }
  const decrement = async () => {
    const storyRef = db.collection('stories').doc('hello-world');
    storyRef.update({ reads: db.FieldValue.increment(-1) });
  }

  const fetchData = async () =>{ getColOrderBy(firebase,setWebsiteList,"defame","ตรวจพบเมื่อปี","desc"); console.log("fetchData(0)",JSON.stringify(websiteList)); }
  const getChart1 = async () =>{
    collection
    .where("ตรวจพบเมื่อปี", "==", "2559")
    .onSnapshot(function(querySnapshot) {
      console.log("querySnapshot.numChildren = ",querySnapshot.size);
  });
  }
  const get = async () => {
    firestore.collection("defame").orderBy("แหล่งที่มา", "desc").get()
    .then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
          console.log(doc.id, " => ", doc.data());
      });
    });
  }
  
  // const onCreate = async ()=>{ 
  // };
  // const onDelete = (id)=>{ collection.doc(id).delete(); }
  // const onUpdate = (id)=>{ collection.doc(id).set({...websiteUpdate,createDate: firebase.firestore.FieldValue.serverTimestamp()}); }
  // const websiteCreateChange = (key,value) => { console.log("Create("+key+","+value+")"); setWebsiteCreate({ ...websiteCreate, [key]: value }); }
  // const websiteUpdateChange = (key, value, index) => { console.log("Update("+key+","+value+","+index+")"); const temp = websiteList[index];temp[key] = value; setWebsiteUpdate(temp); }

  return (
  <div className="App">
      <div>
      <div className="c-content-box c-size-md c-overflow-hide c-bs-grid-small-space c-bg-grey-1" >
        <div className="container">
            <div className="c-content-bar-1 c-opt-1">
                <h3 className="c-font-uppercase c-font-bold ">โพสเนื้อหาที่ไม่เหมาะสม แบ่งตามแหล่งที่มา (ทั้งหมด)</h3>
                <p className="c-font-uppercase">
                  {/* { websiteList.map((item,index) => {
                      return (
                        <>
                        {JSON.stringify(item.ID)}
                        <br/><br/>
                        </>
                      )
                    })
                  } */}
                </p>
            </div>
        </div> 
      </div>
      <br/>
      <br/>
        <ButtonGroup aria-label="Basic example">
          <Button variant="secondary">2559</Button>
          <Button variant="secondary">2560</Button>
          <Button variant="secondary">2561</Button>
          <Button variant="secondary">2562</Button>
          <Button variant="secondary">2563</Button>
          <Button variant="secondary">รวมทุกปี</Button>
        </ButtonGroup>
        <br/>
        <br/>
      <Row>
        
        <Col>
          <div className="container">
              <div className="c-content-bar-1 c-opt-1">
                  <h1 className="c-font-uppercase c-font-bold ">จำนวนทั้งหมด</h1>
                  <br/>
                  <h3>40,169 Post</h3>
                  <br/>
                  <br/>
              </div>
          </div> 
          <CardDeck>
          <Card>
            <Card.Body>
            <Card.Title> <FontAwesomeIcon size="6x" style={{textAlign:"center"}} color="#FF0000" icon={faYoutube}/></Card.Title>
              <Card.Title>Youtube</Card.Title>
              <Card.Text>
                24,756 Post
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
            <Card.Title> <FontAwesomeIcon size="6x" style={{textAlign:"center"}} color="#3b5998" icon={faFacebookSquare}/></Card.Title>
              <Card.Title>Facebook</Card.Title>
              <Card.Text>
                12,320 Post
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
            <Card.Title> <FontAwesomeIcon size="6x" style={{textAlign:"center"}} color="#1DA1F2" icon={faGlobe}/></Card.Title>
              <Card.Title>Other</Card.Title>
              <Card.Text>
                3,093 Post
              </Card.Text>
            </Card.Body>
          </Card>
        </CardDeck>
        </Col>
        <Col>
          <Pie data={data} options={option} />
        </Col>
      </Row>
      <br/>
      <div className="c-content-box c-size-md c-overflow-hide c-bs-grid-small-space c-bg-grey-1" >
        <div className="container">
            <div className="c-content-bar-1 c-opt-1">
                <h3 className="c-font-uppercase c-font-bold ">โพสเนื้อหาที่ไม่เหมาะสม แบ่งตามแหล่งที่มา (ในแต่ละปี)</h3>
                <p className="c-font-uppercase">
                </p>
            </div>
        </div> 
      </div>
      <br/>
      <Row>
        <Col>
          <div style={{minHeight:"400px"}} >
            <Bar
              data={data_source_per_year}
              options={option_source_per_year}
              width={100}
              height={50}
              options={{
                maintainAspectRatio: false
              }}
            />
          </div>
        </Col>
        <Col>
          <Line data={dataLine} options={optionLine} />
        </Col>
      </Row>





      {/* <br/>
      <div className="c-content-box c-size-md c-overflow-hide c-bs-grid-small-space c-bg-grey-1" >
        <div className="container">
            <div className="c-content-bar-1 c-opt-1">
                <h3 className="c-font-uppercase c-font-bold ">อันดับผู้โพสเนื้อหาที่ไม่เหมาะสม (ตามแหล่งที่มา)</h3>
                <p className="c-font-uppercase">
                </p>
            </div>
        </div> 
      </div>
      <br/>
      <Row>
        <Col>
        <Card>
            <Card.Body>
            <Card.Title> <FontAwesomeIcon size="6x" style={{textAlign:"center"}} color="#FF0000" icon={faYoutube}/></Card.Title>
              <Card.Title>Youtube</Card.Title>
              <Card.Text>
                24,756 Post
              </Card.Text>
            </Card.Body>
          </Card>
          <HorizontalBar height="400px" data={data_poster_by_source} options={option_poster_by_source} />
        </Col>
        <Col>
          <Card>
            <Card.Body>
            <Card.Title> <FontAwesomeIcon size="6x" style={{textAlign:"center"}} color="#3b5998" icon={faFacebookSquare}/></Card.Title>
              <Card.Title>Facebook</Card.Title>
              <Card.Text>
                12,320 Post
              </Card.Text>
            </Card.Body>
          </Card>
          <HorizontalBar height="400px" data={data_poster_by_source} options={option_poster_by_source} />
        </Col>
        <Col>
          <Card>
            <Card.Body>
            <Card.Title> <FontAwesomeIcon size="6x" style={{textAlign:"center"}} color="#1DA1F2" icon={faGlobe}/></Card.Title>
              <Card.Title>Other</Card.Title>
              <Card.Text>
                3,093 Post
              </Card.Text>
            </Card.Body>
          </Card>
          <HorizontalBar height="400px" data={data_poster_by_source} options={option_poster_by_source} />
        </Col>
      </Row> */}
      
        
      </div>
      <br/>
      
      <br/>
      


  </div>
  );
}

class Page extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
        <PageFunction firebase={this.props.firebase} />
    );
  }
}

export default withFirebase(Page);