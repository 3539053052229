// import React from 'react'
// import ReactDOM from 'react-dom'
// import './index.css'
// import App from './App'
// import './i18n';
// import { Helmet } from 'react-helmet';
// // import registerServiceWorker from './registerServiceWorker'
// import * as serviceWorker from './script/serviceWorker';
// import { Route, BrowserRouter } from 'react-router-dom'
// import CustomBrowserRouter from "./CustomBrowserRouter";

// import 'bootstrap/dist/css/bootstrap.css';

// const AppWithRouter = () => (
//   <CustomBrowserRouter>
//     <Helmet>
//       <title>WEGET web</title>
//       <link rel="icon" href="app/logo/favicon.ico" />
//       <link rel="apple-touch-icon" href="app/logo/android-chrome-192x192.png" />
//       <link rel="manifest" href="manifest.json" />

//       <meta charset="utf-8" />
//       <meta name="viewport" content="width=device-width, initial-scale=1" />
//       <meta http-equiv="X-UA-Compatible" content="IE=edge" />
//       <meta http-equiv="Content-type" content="text/html; charset=utf-8"/>
//       <meta name="theme-color" content="#000000" />
//     </Helmet>
//     <App />
//   </CustomBrowserRouter>
// )


// ReactDOM.render(<AppWithRouter />, document.getElementById('root'))
// // registerServiceWorker()
// serviceWorker.register()
// serviceWorker.unregister()



// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import * as serviceWorker from './script/serviceWorker';

// import 'bootstrap/dist/css/bootstrap.css';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// import { BrowserRouter } from 'react-router-dom'

// const AppWithRouter = () => (
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();



import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import * as serviceWorker from './script/serviceWorker';

// import App from './components/App';
// import App from './App';
// import App from './App_Traffic';
import App from './App_csoc';
import Firebase, { FirebaseContext } from './components/Firebase';

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    {/* <img src={logo} className="App-logo" alt="logo" /> */}
    <div>loading...</div>
  </div>
);

ReactDOM.render(
  <FirebaseContext.Provider value={ new Firebase() }>
    <Suspense fallback={ <Loader /> }>
        {/* <App/> */}
        {/* <App {...this.props} firebase={this.props.firebase} /> */}
        <App />
      </Suspense>
  </FirebaseContext.Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

