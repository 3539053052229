import React, { useState, Component } from 'react'
import { Link } from 'react-router-dom'
import { Nav, Navbar, NavDropdown, Form, FormControl, Button, NavItem, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AuthUserContext } from '../components/Session'
import { withFirebase } from '../components/Firebase'
import AuthenticationModel from '../pages/NavigationAuthentication'
{
  // let { i18n } = useTranslation()
  // let changeLanguage = lng => {
  //   i18n.changeLanguage(lng)
  // };
}
const Page = (props) => (
  <>
        <Navbar bg="white" expand="md" fixed="top" variant="light" >
            <div className="container">
            <Navbar.Brand href="/"><img src={'/app/logo_mdes/android-chrome-512x512.png'} style={{maxHeight:"50px"}} className="" alt="logo" /></Navbar.Brand>
            <Navbar.Brand href="/">MDES</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <AuthUserContext.Consumer>
                    {authUser =>
                      authUser ? (
                        <>
                          <Nav.Link href="/">Dashboard</Nav.Link>
                              <Nav.Link href="/chartPosterBySource">ผู้โพสต์ตามแหล่งที่มา</Nav.Link>
                              {/* E แหล่งที่มา */}
                              {/* <Nav.Link href="/chartTest">ชื่อเว็บ</Nav.Link> */}
                              <Nav.Link href="/chartPoster">ผู้โพสต์</Nav.Link>
                              {/* H ผู้โพสต์ Ho Bar */}
                              <Nav.Link href="/chartMeasure">มาตรการ</Nav.Link>
                              {/* J มาตรการ Bar */}
                              <Nav.Link href="/chartExaminer">ผู้ตรวจสอบ</Nav.Link>
                              {/* I ผู้ตรวจสอบ Ho Bar */}
                              <Nav.Link href="/chartPerformanceResult">ผลการปฏิบัติ</Nav.Link>
                            </>
                      ) : ""
                    }
                  </AuthUserContext.Consumer>
                    {/* <Nav.Link href="#home">Home</Nav.Link> */}
                    
                    {/* L ผลการปฏิบัติ Bar */}
                    {/* <Nav.Link href="/chartCurrentStatus">สถานะปัจจุบัน</Nav.Link> */}
                    {/* V สถานะปัจจุบัน Ho Bar */}
                    



                    <AuthUserContext.Consumer>
                    {authUser =>
                      authUser ? (
                        <>
                          
                          { authUser && authUser.roles && (!!authUser.roles["parking"] || !!authUser.roles["ADMIN"])  && (
                            <>
                              {/* <NavDropdown title="ลานจอดรถ">
                                <NavDropdown.Item href="../carSearch">ค้นหารถ</NavDropdown.Item>
                                <NavDropdown.Item href="../carListForParking">รายการรถฝากจอด</NavDropdown.Item>
                              </NavDropdown> */}
                            </>
                          )}





                          

                        </>
                      ) : ""
                    }
                  </AuthUserContext.Consumer>
                </Nav>
                <Nav className="">
                {/* <Form inline>
                    <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                    <Button variant="outline-success">Search</Button>
                </Form> */}
                {/* <Nav.Link href="#about">{i18n.t('MENU.ABOUT')}</Nav.Link>
                <Nav.Link href="#contact">{i18n.t('MENU.CONTACT')}</Nav.Link> */}
                {/* <NavDropdown title="Language" className="">
                    <NavDropdown.Item href="/" onClick={() => changeLanguage('en')}>English</NavDropdown.Item>
                    <NavDropdown.Item href="/" onClick={() => changeLanguage('th')}>Thai</NavDropdown.Item>
                </NavDropdown> */}
                <AuthUserContext.Consumer>
                  { authUser=>
                    <AuthenticationModel firebase={props.firebase} authUser={authUser}/>
                  }
                </AuthUserContext.Consumer>
                {/* <Button variant="outline-primary" onClick={setShow(true)}>Sign In</Button> */}
                </Nav>
                {/* <Form inline>
                  <FormControl type="text" placeholder="User" className="mr-sm-1" />
                  <FormControl type="password" placeholder="Pass" className="mr-sm-1" />
                  <Button variant="outline-success">Sign In</Button>
                </Form> */}
                
            </Navbar.Collapse>
            </div>
        </Navbar>

        
      </>
)

export default withFirebase(Page)

  



