
import React,{useState} from 'react';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Helmet from 'react-helmet';
// import firebase from '../config/firebase_config';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe,faHome, faPhoneSquare} from "@fortawesome/free-solid-svg-icons";
import { faLine, faFacebookSquare, faInstagramSquare, faTwitterSquare, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'
// import { Nav, Navbar, NavDropdown, Form, FormControl, Button, ButtonGroup, InputGroup, Modal, Row, Col } from 'react-bootstrap';
import { Nav, Navbar, NavDropdown, Form, FormControl, Button, ButtonGroup, InputGroup, Modal, Row, Col, CardDeck, Card, Container } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import { FacebookProvider, MessageUs } from 'react-facebook';
import { Link } from 'react-router-dom';
import { withFirebase } from '../components/Firebase';

const firebase = withFirebase;

export const noImage = "../appDefault/600x600.webp";
export const getIdFromPath = (point) =>{
  var words = window.location.href.split('/');
  if(words.length == 5){
    var words1 = words[point].split("#")[0];
    var param = words1.split("/")[0];
    console.log("getIdFromPath  = ",param);
    return param;
  }else
    return null;
  
}

export const JsonPretty = (p) =>{
  return <JSONPretty style={{textAlign:"left"}} data={p.data} ></JSONPretty>;
}
// var local = "th-TH";
// export default function Project() {
// }
export const dateFormat = (date) => {
    // console.log(date);
    if(date != null){
        // return new Date(date.toDate()).toLocaleDateString("en-US");
        return new Intl.DateTimeFormat("th-TH", {
        year: "numeric", month: "long", day: "2-digit",
        }).format(date.toDate());
    }
}
export const dateValue = (date) => {
  if(date != null && !(date instanceof Date)){
      console.log("date ===== ", JSON.stringify(date) );
      return new Intl.DateTimeFormat("en-US", {
      year: "numeric", month: "2-digit", day: "2-digit",
      }).format(date.toDate());
  }else
    return date;
}
function toDateTime(secs) {
  var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
}
export const formatDate = (date) => {
  var date1 = date.seconds != null
   ? new Date(date) // Already a javascript date object
   : date;
   return date1;
}
const db = withFirebase.firestore;
// getDocById(setDoc, setDocId, "company", idFromPath);
export const getDocById = (setDoc, setDocId, docName, docId) => {
  if(docId){
    db.collection(docName)
    .doc(docId).get().then((doc) => {
        setDocId(doc.id);       console.log(docName+" docId =", doc.id );
        setDoc(doc.data());     console.log(docName+" doc =", JSON.stringify(doc.data()));
    })
  }
}
export const updateDocById = (docName, clearDoc, doc, docId) => {
  console.log("trying update document with id = ", docId);
  console.log(docName+" =  ", JSON.stringify(doc));
  db.collection(docName)
  .doc(docId).set({...doc,updateDate: firebase.firestore.FieldValue.serverTimestamp()});
  // const updateRef = db.collection('company').doc(docId);
  // updateRef.set({
  //   doc   
  // }).then((updateRef) => {
  //   clearDoc()
  // })
  // .catch((error) => {
  //   console.error("Error update document: ", error);
  // });
  clearDoc();
  alert('save แล้วนะ');
}


export const loadData = (table, setTable, tableName) => {
    const firestore = withFirebase.firestore;
    if(tableName != null && tableName.length > 0){
      const collection = firestore.collection(tableName);
      collection.orderBy("createDate","desc")
          .onSnapshot(function(data){
            // if (data) {
            //   console.log(`timestamp:aaaaaaaa(actual)`);
              // if (!data.startDate && snap.metadata.hasPendingWrites) {
              //     // we don't have a value for createdAt yet
              //     const ts = firebase.firestore.Timestamp.now()
              //     console.log(`timestamp: ${ts} (estimated)`)
              // }
              // else {
              //     // now we have the final timestamp value
              //     console.log(`timestamp: ${data.startDate} (actual)`)
              // }
            // }
            console.log("fetch "+tableName+" size = ", data.docs.length);
            setTable(data.docs.map(doc => ({...doc.data(), id:doc.id})));
        });
    }
  }
export const loadMaster = (master, setMaster, tableName) => {
    const firestore = firebase.firestore();
    firestore.collection(tableName)
    // .orderBy("createDate","desc")
    .onSnapshot(function(data){
      // console.log("mater data = ", data.docs);
      const list= (data.docs.map(doc => ({...doc.data(), value:doc.id} )));
      const masterTemp = master;
      masterTemp[tableName] = list;
      setMaster(masterTemp);
    });
    return ;
  }
export const uploadImage = (taskCreate, setTaskCreate, path, image) => {
    if(image){
        const storage = firebase.storage();
        const uploadTask = storage.ref(`${path}/${image.name}`).put(image);
        uploadTask.on(
            "state_changed",
            snapshot => {
            // progress function ...
            const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            },
            error => {
            // Error function ...
            console.log(error);
            },
            () => {
            // complete function ...
            storage
                .ref(path)
                .child(image.name)
                .getDownloadURL()
                .then(url => {
                    console.log("uploaded url = ",url);
                    setTaskCreate({ ...taskCreate, image: url });
                });
            }
        );
    }
};
const storage = firebase.storage;
export const uploadImageDynamic = (taskCreate, setTaskCreate, inputName, image, path) => {
    if(image){
        const uploadTask = storage.ref(`${path}/${image.name}`).put(image);
        uploadTask.on(
            "state_changed",
            snapshot => {
            // progress function ...
            const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            },
            error => {
            // Error function ...
            console.log(error);
            },
            () => {
            // complete function ...
            storage
                .ref(path)
                .child(image.name)
                .getDownloadURL()
                .then(url => {
                    console.log("uploaded url = ",url);
                    setTaskCreate({ ...taskCreate, [inputName]: url });
                });
            }
        );
    }
};
export const uploadImageDynamicBack = (uploadResult, setUploadResult, inputName, image, path) => {
    if(image){
        const uploadTask = storage.ref(`${path}/${image.name}`).put(image);
        uploadTask.on(
            "state_changed",
            snapshot => {
            // progress function ...
            const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            },
            error => {
            // Error function ...
            console.log(error);
            },
            () => {
            // complete function ...
            storage
                .ref(path)
                .child(image.name)
                .getDownloadURL()
                .then(url => {
                    console.log("uploaded url = ",url);
                    setUploadResult(url);
                    // setTaskCreate({ ...taskCreate, [inputName]: url });
                });
            }
        );
    }
};

// onst getUsers = (id) => {
//     return axios.get(base_url + '/users' + id)
// }


// const [master, setMaster] = useState({
  //   "m1":[{value:"m1-11",label:"aaa"},{value:"m1-12",label:"bbb"}]
  //   , "m2":[{value:"m2-11",label:"ccc"},{value:"m2-12",label:"ddd"}]
  // });


// function newTaskHandleChange1(evt) {
  //   // console.log("mmmmmmmmm ====== ",JSON.stringify(master));
  //   const value = evt.target.value;
  //   setNewTask({ ...newTask, [evt.target.name]: value });
  // }




//############################## Helmet ####################################

//   handleChange = (event) => {
//     this.setState(state => ({
//       editTodo: {
//         ...state.editTodo,
//         title: event.target.value,
//       },
//     }));
// }

// {customHelmet("/app/logo/favicon.ico"
//                       ,"/app/logo/android-chrome-192x192.png"
//                       ,"image"
//                       ,"/manifest.json"
//                       ,"title"
//                       ,"keywords"
//                       ,"description"
//                       ,"author"
//                       ,"url")}
export const customHelmet = (favicon,logo192,image,mainifest,title,keywords,description,author,url) =>{
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" href={favicon} />
      <link rel="apple-touch-icon" href={logo192} />
      <link rel="manifest" href={mainifest} />

      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta http-equiv="Content-type" content="text/html; charset=utf-8"/>
      <meta name="theme-color" content="#000000" />

      <meta name="title" content={title} />
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
      <meta name="author" content={author} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content={title} />
      <meta property="og:title" content={keywords} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={keywords} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}
export const customManifest = (favicon, logo192, logo512, short_name, name) =>{
  return {
    "short_name": short_name,
    "name": name,
    "icons": [
      {
        "src": favicon,
        "sizes": "64x64 32x32 24x24 16x16",
        "type": "image/x-icon"
      },
      {
        "src": logo192,
        "type": "image/png",
        "sizes": "192x192"
      },
      {
        "src": logo512,
        "type": "image/png",
        "sizes": "512x512"
      }
    ],
    "start_url": ".",
    "display": "standalone",
    "theme_color": "#000000",
    "background_color": "#ffffff"
  }
  
}
//############################## Element ####################################
// const Container = () =>{
//   return <div className="container"></div>
// }
export const ElmentShow = (props) =>{
  var i = props.i;
  var item = props.item;
  var link = item.link;
  var noFullWidth = item.noFullWidth || null ? true : false;
  var menuLink = item.menuLink;
  var alignLeft = item.alignLeft ? "left" : '' ;
  var color = item.color;
  var bold = item.bold ? "bold" : '' ;
  var underline = item.underline ? "underline" : '' ;
  return <>
    {
      <div id={menuLink && menuLink != "" ? menuLink : "" } className={(noFullWidth ? "container" : "")} style={{
          textAlign:alignLeft
          ,color:color
          // ,fontWeight:"bold"
          ,textDecoration:underline
        }}>
        { (link != null && link != '' ? true : false)
          ? <a href={link}><ElmentShowItem i={i} item={item}  /></a>
          : <ElmentShowItem i={i} item={item} />
        }
      </div>
    }
  </>
}
export const ElmentShowItem = (props) =>{
  var i = props.i;
  var item = props.item;
  switch (item.element) {
      case 'text': return ( item.value );
      case 'textSector':
          return (
            <div className="c-content-box c-size-md c-overflow-hide c-bs-grid-small-space c-bg-grey-1" >
              <div className="container">
                  <div className="c-content-bar-1 c-opt-1">
                      <h3 className="c-font-uppercase c-font-bold ">{item.value}</h3>
                      <p className="c-font-uppercase">
                      </p>
                  </div>
              </div> 
            </div>
          );
      case 'textTitle':
          return (
            <h2 className="display-4">{item.value} </h2>
          );
      case 'textMessage':
          return (
            <p className="lead">{item.value}</p>
          );
      case 'image':
        return (
          <LazyLoadImage src={item.value && item.value!= "" ? item.value : "../appDefault/1920x1080.webp" } alt="Images" style={{maxHeight:"100%",maxWidth:"100%"}} effect="blur" />
          // <LazyLoadImage src={item.value} alt="Images" maxHeight="100%" maxWidth="100%"   style={{maxHeight:"100%",maxWidth:"100%"}} />
          // <img src={item.value} alt="Uploaded Images" style={{maxHeight:"100%",maxWidth:"100%"}} />
        );
        case 'youtube':
          return (
            <div className="video" style={{ position: "relative", paddingBottom: "56.25%" /* 16:9 */, paddingTop: 25, height: 0 }} >
                <iframe style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} src={`https://www.youtube.com/embed/${item.value}`} frameBorder="0" />
            </div>
          );
        case 'cardDeck':
          return (
            <CardDeck>
              <Card>
                <LazyLoadImage src={item.value && item.value!= "" && item.value.image1!= "" ? item.value : "../appDefault/600x600.webp" } alt="Images" style={{maxHeight:"100%",maxWidth:"100%"}} effect="blur" />
              </Card>
              <Card>
                <LazyLoadImage src={item.value && item.value!= "" ? item.value : "../appDefault/600x600.webp" } alt="Images" style={{maxHeight:"100%",maxWidth:"100%"}} effect="blur" />
              </Card>
              <Card>
                <LazyLoadImage src={item.value && item.value!= "" ? item.value : "../appDefault/600x600.webp" } alt="Images" style={{maxHeight:"100%",maxWidth:"100%"}} effect="blur" />
              </Card>
            </CardDeck>
          );
      default:
        return null;
    }
}


// const ElmentInput = (props) =>{
//   var i = props.i;
//   var item = props.item;
// switch (item.element) {
//   case 'text':
//     return (
//       <>
//           <FormControl name="value" className="control-input" placeholder="Enter Text" value={item.value} onChange={e => handleInputChange(e, i)} />
//       </>
//     );
//   case 'textSector':
//       return (
//         <>
//             <FormControl name="value" className="control-input" placeholder="Enter Text" value={item.value} onChange={e => handleInputChange(e, i)} />
//         </>
//       );
//   case 'textTitle':
//       return (
//           <>
//               <FormControl name="value" className="control-input" placeholder="Enter Text" value={item.value} onChange={e => handleInputChange(e, i)} />
//           </>
//       );
//   case 'textMessage':
//       return (
//           <>
//               <FormControl name="value" className="control-input" placeholder="Enter Text" value={item.value} onChange={e => handleInputChange(e, i)} />
//           </>
//       );
//   case 'image':
//     return (
//       <>
//       </>
//     );
//     case 'youtube':
//       return (
//         <>
//           <FormControl name="value" className="control-input" placeholder="Enter Youtube Url" value={item.value} onChange={e => handleInputChange(e, i)} />
//         </>
//       );
//   default:
//     return null;
// }
// } 


// [
//   'primary',
//   'secondary',
//   'success',
//   'danger',
//   'warning',
//   'info',
//   'light',
//   'dark',
// ].map((variant, idx) => (
//   <Alert key={idx} variant={variant}>
//     This is a {variant} alert—check it out!
//   </Alert>
// ));
export const _Alert = (variant,title,message) => {
  return(
    <Alert variant={variant}>
      <Alert.Heading>{title}</Alert.Heading>
      <p>{message}</p>
    </Alert>
  )
}

// [image : {group}]
export const Gallery = ({ images, scrollPosition }) => (
  <div>
    // We are loading landscape.jpg here
    <img src="/landscape.jpg" alt="Beautiful landscape" />
    {images.map((image) =>
      <LazyLoadImage
        key={image.key}
        alt={image.alt}
        scrollPosition={scrollPosition}
        src={image.src}
        // If the image we are creating here has the same src than before,
        // we can directly display it with no need to lazy-load.
        visibleByDefault={image.src === '/landscape.jpg'} />
    )}
  </div>
);

const aaa = (props) =>{
  return (
    // <Link to={props.url}></Link>
    <a href={props.url} target="_blank" rel="noopener noreferrer"></a>
  )
}
export const ContactIcon = (props) =>{
  const contactType = props.contactType;
  const scale = props.scale != null && props.scale.length ? props.scale : "4x";
  const marginLeft =  props.marginLeft != null && props.marginLeft.length ? props.marginLeft : "10px";
  return (
    <>
      { contactType == "phone" ?
        <FontAwesomeIcon size={scale} style={{marginLeft:{marginLeft}}} color="#CACA30" icon={faPhoneSquare}/> : "" }
      { contactType == "facebook" ?
        <FontAwesomeIcon size={scale} style={{marginLeft:{marginLeft}}} color="#3b5998" icon={faFacebookSquare}/> : "" }
      { contactType == "line" ?
        <FontAwesomeIcon size={scale} style={{marginLeft:{marginLeft}}} color="#00c300" icon={faLine}/> : "" }
      { contactType == "instagram" ?
        <FontAwesomeIcon size={scale} style={{marginLeft:{marginLeft}}} color="#9b6954" icon={faInstagramSquare}/> : "" }
      { contactType == "twitter" ?
        <FontAwesomeIcon size={scale} style={{marginLeft:{marginLeft}}} color="#1DA1F2" icon={faTwitterSquare}/> : "" }
      { contactType == "youtube" ?
        <FontAwesomeIcon size={scale} style={{marginLeft:{marginLeft}}} color="#FF0000" icon={faYoutube}/> : "" }
      { contactType == "linkedin" ?
        <FontAwesomeIcon size={scale} style={{marginLeft:{marginLeft}}} color="#1DA1F2" icon={faLinkedin}/> : "" }
    </>
  )
}
export const ContactIconBar = (props) =>{
  const contact = props.contact;
  const scale = props.scale != null && props.scale.length ? props.scale : "4x";
  const marginLeft =  props.marginLeft != null && props.marginLeft.length ? props.marginLeft : "8px";
  return (
    <>
    {/* {JSON.stringify(contact)} */}
    { contact 
      ? <>
          { contact.phone && contact.phone.contact_id != null && contact.phone.contact_id != "" ? 
            <a href={"tel:"+contact.phone.contact_id} style={{marginLeft:"8px"}} ><ContactIcon contactType="phone" size={scale} /></a> : "" }

          { contact.facebook && contact.facebook.contact_id != null && contact.facebook.contact_id != "" ? 
            <a href={"https://www.facebook.com/"+contact.facebook.contact_id} style={{marginLeft:"8px"}}><ContactIcon contactType="facebook" size="4x" /></a> : "" }

          { contact.line && contact.line.contact_id != null && contact.line.contact_id != "" ? 
            <a href={"http://line.me/ti/p/~"+contact.line.contact_id} style={{marginLeft:"8px"}}><ContactIcon contactType="line" size={scale} /></a> : "" }

          { contact.instagram && contact.instagram.contact_id != null && contact.instagram.contact_id != "" ? 
            <a href={"https://www.instagram.com/"+contact.instagram.contact_id} style={{marginLeft:"8px"}}><ContactIcon contactType="instagram" size={scale} /></a> : "" }

          { contact.twitter && contact.twitter.contact_id != null && contact.twitter.contact_id != "" ? 
            <a href={"https://www.twitter.com/"+contact.twitter.contact_id} style={{marginLeft:"8px"}}><ContactIcon contactType="twitter" size={scale} /></a> : "" }

          { contact.youtube && contact.youtube.contact_id != null && contact.youtube.contact_id != "" ? 
            <a href={"https://www.youtube.com/channel/"+contact.youtube.contact_id} style={{marginLeft:"8px"}}><ContactIcon contactType="youtube" size={scale} /></a> : "" }

          { contact.linkedin && contact.linkedin.contact_id != null && contact.linkedin.contact_id != "" ? 
            <a href={"https://www.linkedin.com/"+contact.linkedin.contact_id} style={{marginLeft:"8px"}}><ContactIcon contactType="linkedin" size={scale} /></a> : "" }
        </>
      : ""
    }
    </>
  )
}
export const ContactIconSector = (props) =>{
  const contact = props.contact;
  const scale = props.scale != null && props.scale.length ? props.scale : "4x";
  const marginLeft =  props.marginLeft != null && props.marginLeft.length ? props.marginLeft : "5px";
  return (
    <>
      { contact 
        ? <>
            <ContactIconBar contact={contact} scale={scale} marginLeft={marginLeft}/>
          </>
        : ""
      }
    </>
  )
}



//////   facebook

export const FacebookMessageUs = (appId, messengerAppId, pageId) =>{
  return (
      <FacebookProvider appId={appId}>
        <MessageUs messengerAppId={messengerAppId} pageId={pageId}/>
      </FacebookProvider>
  )
}
