
import React,{useState, Component, useEffect} from 'react';
import { AuthUserContext } from '../components/Session'
import { withFirebase } from '../components/Firebase';

import { Nav, Navbar, NavDropdown, Form, FormControl, Button, NavItem, Modal, Card, CardDeck,ButtonGroup, Row as Row, Col, Container } from 'react-bootstrap'

import { faGlobe,faFlag, faPhoneSquare} from "@fortawesome/free-solid-svg-icons";
import { faLine, faFacebookSquare, faInstagramSquare, faTwitterSquare, faYoutube,  } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {Pie, Bar, Line, HorizontalBar} from 'react-chartjs-2';
// #####################        Poster by Source (Ho Bar)        #####################

const data_poster_by_source = {
  labels: [
    "HaunT89s","sanamluang","สหพันธรัฐไ","Trairong S","FAIYEN CHA","ชาวนา","thai silen","-","สหพันธรัฐไ","TRUTH CHAN","ป้าหนิง ดี","Thai Democ","Nin Berger","สหพันธรัฐไ","YAMMY CHAN","Media Forc","Thai Proto","นางแมวจอมว","Thai Democ","หลวง ไทย"
  ],
  datasets: [
    {
      label: 'รายการ',
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [
        3241,1205,1010,945,798,776,745,732,685,587,552,541,525,518,486,482,470,466,461,433
      ]
    }
  ]
};
const option_poster_by_source = {
  "hover": {
    "animationDuration": 0
  },
  "animation": {
    "duration": 1,
    "onComplete": function() {
      var chartInstance = this.chart,
        ctx = chartInstance.ctx;

      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.fontStyle = "bold";
      ctx.fillStyle = "#0000FF";

      this.data.datasets.forEach(function(dataset, i) {
        var meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach(function(bar, index) {
          var data = dataset.data[index];
          ctx.fillText(data, bar._model.x, bar._model.y);
        });
      });
    }
  },
  scales: { 
      yAxes: [{
          ticks: {
            max: Math.max(...data_poster_by_source.datasets[0].data) + 10,
            beginAtZero: true
          }
      }],
      xAxes: [{
        ticks: {
          max: Math.max(...data_poster_by_source.datasets[0].data) + 10,
          beginAtZero: true
        }
    }]
  }
}






const PageFunction = (props) => {
  const firebase = props.firebase;
  const firestore = props.firebase.firestore;
  const db = props.firebase.firestore;
  const collection = db.collection("website");
  
  const [websiteList, setWebsiteList] = useState([]);
  const [websiteCreate, setWebsiteCreate] = useState({startDate:new Date()})
  const [websiteUpdate, setWebsiteUpdate] = useState({});
  const [master, setMaster] = useState({});
  const masterList = ["m1","m2"];

  React.useEffect(()=>{
    // const fetchData = async () =>{ getCol(firebase,setWebsiteList,"website") }
    // fetchData(0);
  },[]);
  
  // const onCreate = async ()=>{ 
  // };
  // const onDelete = (id)=>{ collection.doc(id).delete(); }
  // const onUpdate = (id)=>{ collection.doc(id).set({...websiteUpdate,createDate: firebase.firestore.FieldValue.serverTimestamp()}); }
  // const websiteCreateChange = (key,value) => { console.log("Create("+key+","+value+")"); setWebsiteCreate({ ...websiteCreate, [key]: value }); }
  // const websiteUpdateChange = (key, value, index) => { console.log("Update("+key+","+value+","+index+")"); const temp = websiteList[index];temp[key] = value; setWebsiteUpdate(temp); }

  return (
  <div className="App">
    <div>




      <br/>
      <div className="c-content-box c-size-md c-overflow-hide c-bs-grid-small-space c-bg-grey-1" >
        <div className="container">
            <div className="c-content-bar-1 c-opt-1">
                <h3 className="c-font-uppercase c-font-bold ">ผู้โพส</h3>
                <p className="c-font-uppercase">
                </p>
            </div>
        </div> 
      </div>
      <br/>
      <Container>

      <Row>
        <Col>
          <HorizontalBar height="150px" data={data_poster_by_source} options={option_poster_by_source} />
        </Col>
      </Row>
      </Container>
      
        
      </div>
      <br/>
      
      <br/>
      


  </div>
  );
}

class Page extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
        <PageFunction firebase={this.props.firebase} />
    );
  }
}

export default withFirebase(Page);