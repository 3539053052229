import React, { useState, useEffect, Component } from "react";
import {Link,useHistory} from 'react-router-dom'
// import {getIdFromPath, getDoc, getDocById, updateDocById, noImage, uploadImage,uploadImageDynamic, dateFormat} from '../config/projectConfig';
import {JsonPretty, ElmentShow, FacebookMessageUs, ContactIcon,ContactIconBar,ContactIconSector} from '../config/projectConfig';
import { Nav, Navbar, NavDropdown, Form, FormControl, Button, ButtonGroup, InputGroup, Modal, Row, Col, Container } from 'react-bootstrap';

import { withFirebase } from '../components/Firebase'
import { LabelTextInput, LabelImageInput, TextInput, ImageInput } from '../config/formUtils'
import { loadMaster, dateFormat, uploadImage, getDoc, getDocById, getCol, getColById, updateDocById, uploadImageDynamic, getIdFromPath } from '../config/dbUtils'
import excelToJson from 'convert-excel-to-json'
import fs from 'fs'
import readXlsxFile from 'read-excel-file'
import axios from 'axios'
import XLSX from "xlsx";
// import XMLData from 'https://firebasestorage.googleapis.com/v0/b/upbrand2/o/images%2F%E0%B8%A3%E0%B8%A7%E0%B8%A1%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B8%B2%E0%B8%A1%E0%B8%AB%E0%B8%A1%E0%B8%B4%E0%B9%88%E0%B8%99%E0%B8%AA%E0%B8%96%E0%B8%B2%E0%B8%9A%E0%B8%B1%E0%B8%99-20200926_cut2_test.xlsx?alt=media&token=04b548a9-b161-4561-bb9e-808b187b774d'



const Page = (props) => {
  const firebase = props.firebase;
  const firestore = props.firebase.firestore;
  const db = props.firebase.firestore;

    const [hideControl, setHideControl] = useState(false);
    const [helmet, setHelmet] = useState({}); 
    const [docId,setDocId] = useState(0);
    const [doc, setDoc] = useState({}); 
    const [cols, setCols] = useState({}); 
    const [data, setData] = useState({}); 
    useEffect(() => {
    },[]);
    const handleFile = (file /*:File*/) => {
      /* Boilerplate to set up FileReader */
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = e => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, {
          header: 1
        });
        /* Update state */
        setData(data)
        setCols(make_cols(ws["!ref"]))
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    }
    const exportFile = () => {
      /* convert state to workbook */
      const ws = XLSX.utils.aoa_to_sheet(this.state.data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      /* generate XLSX file and send to client */
      XLSX.writeFile(wb, "sheetjs.xlsx");
    }
    const updateData = () => {
      // Get a new write batch
      var batch = db.batch();

      if(data.length > 0){
        let docData = []
        for (let i = 0; i < data.length; i++) {
          if(i >0){
            const row = {}//data[i];
            // row[i] = {}
            for (let j = 0; j < data[0].length; j++) {
              const key = data[0][j];
              const value = data[i][j]
              // row[j] = {}
              row[key] = value != null ? value : "" ;
              
              // console.log(i+" "+ data[0][j] +" /"+j+" / "+element)
            }
            docData[i] = row
  
            var rowUpdate = db.collection("defame").doc(i.toString());
            batch.set(rowUpdate, row);
            
            // console.log(row) 
          }
        }
        console.log(JSON.stringify(docData)) 
      }

      // // Update the population of 'SF'
      // var sfRef = db.collection("cities").doc("SF");
      // batch.update(sfRef, {"population": 1000000});

      // // Delete the city 'LA'
      // var laRef = db.collection("cities").doc("LAA");
      // batch.delete(laRef);

      // Commit the batch
      batch.commit().then(function () {
          // ...
          console.log("batch.commit()")
      });
    }
      return (
        <div className="App" >   
            <div className="row">
                <div className="col-xs-12">
                  <button
                    className="btn btn-success"
                    onClick={updateData}
                  >
                    save
                  </button>
                </div>
              </div>
            <h1>ExcelToJsonTest</h1>
            <DragDropFile handleFile={handleFile}>
              <div className="row">
                <div className="col-xs-12">
                  <DataInput handleFile={handleFile} />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <button
                    disabled={data.length}
                    className="btn btn-success"
                    onClick={exportFile}
                  >
                    Export
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <OutTable data={data} cols={cols} />
                </div>
              </div>
              {/* <div className="row">
                <div className="col-xs-12">
                  <pre>{JSON.stringify(data, null, 1)}</pre>
                </div>
              </div> */}
            </DragDropFile>
            {JSON.stringify(doc)}
            

            <Form.Check type="checkbox" name="hideBtn" label="Hide Control" style={{textAlign:"left",marginLeft:"15px"}} onChange={e => setHideControl(e.target.checked ? true : false)} />
            <div hidden={hideControl}> <JsonPretty style={{textAlign:"left"}} data={data}></JsonPretty> </div>
        </div>
    );
}
  
class ExportPage extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
        <Page firebase={this.props.firebase} />
    );
  }
}

export default withFirebase(ExportPage);

class DragDropFile extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
      >
        {this.props.children}
      </div>
    );
  }
}

class DataInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <form className="form-inline">
        <div className="form-group">
          <label htmlFor="file">Spreadsheet</label>
          <input
            type="file"
            className="form-control"
            id="file"
            accept={SheetJSFT}
            onChange={this.handleChange}
          />
        </div>
      </form>
    );
  }
}

/*
  Simple HTML Table
  usage: <OutTable data={data} cols={cols} />
    data:Array<Array<any> >;
    cols:Array<{name:string, key:number|string}>;
*/
class OutTable extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              {this.props.cols.length && this.props.cols.map(c => (
                <th key={c.key}>{c.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.props.data.length && this.props.data.map((r, i) => (
              <tr key={i}>
                { this.props.cols.length && this.props.cols.map(c => (
                  <td key={c.key}>{r[c.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm"
]
  .map(function(x) {
    return "." + x;
  })
  .join(",");

/* generate an array of column objects */
const make_cols = refstr => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
