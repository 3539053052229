import React from 'react';

  export default function main() {
    return (
      <div>
          {/* <div className="c-layout-breadcrumbs-1 c-fonts-uppercase c-fonts-bold c-bordered c-bordered-both">
	<div className="container">
		<div className="c-page-title c-pull-left">
			<h3 className="c-font-uppercase c-font-sbold">Coming Soon</h3>
		</div>
		<ul className="c-page-breadcrumbs c-theme-nav c-pull-right c-fonts-regular">
		</ul>
	</div>
</div> */}
<br/>
      <div className="c-content-box c-size-md c-overflow-hide c-bs-grid-small-space c-bg-grey-1" >
        <div className="container">
            <div className="c-content-bar-1 c-opt-1">
                <h3 className="c-font-uppercase c-font-bold ">Coming Soon</h3>
                <p className="c-font-uppercase">
                </p>
            </div>
        </div> 
      </div>
      <br/>
<br/><br/><br/><br/><br/><br/><br/>
<div className="c-content-box c-size-md">
	<div className="container">
		<div className="c-content-bar-1 c-opt-1 c-bordered c-theme-border c-shadow">
			<h3 className="c-font-uppercase c-font-bold">This page is under construction</h3>
			<p className="c-font-uppercase">
				You can come back next time.<br/>
			</p>
			<a href="/" className="btn btn-md c-btn-square c-theme-btn btn-primary c-btn-bold">Back to Home Page</a>
		</div>
	</div> 
</div>
<br/><br/><br/><br/><br/><br/><br/>

      </div>
    );
  }

  