import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Redirect, useLocation } from 'react-router-dom';
import './App.css';
import GoogleFontLoader from 'react-google-font-loader';
import { geolocated } from "react-geolocated";

import { AuthUserContext, withAuthentication } from './components/Session';

import Navigation from './pages_csoc/Navigation';
import Footer from './pages/Footer';
import ThankYou from './pages/ThankYou';
import ComingSoon from './pages/ComingSoon';

import XLSX from './pages/XLSX';
import chartTest from './pages_csoc/chartTest';
import chartPosterBySource from './pages_csoc/chartPosterBySource';
import chartMeasure from './pages_csoc/chartMeasure';
import chartCurrentStatus from './pages_csoc/chartCurrentStatus';
import chartExaminer from './pages_csoc/chartExaminer';
import chartPoster from './pages_csoc/chartPoster';
import chartPerformanceResult from './pages_csoc/chartPerformanceResult';
// import chartMeasure from './pages_csoc/chartMeasure';


const App = (props) => (
  
    <Router>
      <GoogleFontLoader
      fonts={[ { font: 'Sarabun', weights: [400, '400'], }]} />
      
      <div className="App" style={{fontFamily: 'Sarabun, sans-serif'}}>
        <Navigation />
        <div style={{marginTop:"80px"}}></div>
        <AuthUserContext.Consumer>
          { authUser => authUser && authUser.roles && !!authUser.roles["ADMIN"] && (
              <> <hr/> {JSON.stringify(authUser)} <hr/> </>
          )}
        </AuthUserContext.Consumer>

        <Route exact path="/comingSoon" component={ComingSoon} />
        <Route exact path="/XLSX" component={XLSX} />
        <Route exact path="/" component={chartTest} />
        <Route exact path="/chartTest" component={chartTest} />
        <Route exact path="/chartPosterBySource" component={chartPosterBySource} />
        <Route exact path="/chartMeasure" component={chartMeasure} />
        <Route exact path="/chartCurrentStatus" component={chartCurrentStatus} />

        <Route exact path="/chartExaminer" component={chartExaminer} />
        <Route exact path="/chartPoster" component={chartPoster} />
        <Route exact path="/chartPerformanceResult" component={chartPerformanceResult} />
        {/* <Route exact path="/chartMeasure" component={chartMeasure} /> */}
        
        
      </div>
    </Router>
)
export default withAuthentication(App);




